<template>
  <v-container fill-height>
    <div class="jumbotron jumbotron-fluid" style="height: 100vh">
      <h1 class="text-center text-Danger" style="border-radius: 10px">
        Thank you all for participating !
      </h1>
      <p>&nbsp;</p>

      <div class="row">
        <div class="col-sm-12 fhg-wrapper">
          <h1
            class="text-center success text-primary"
            style="border-radius: 10px"
          >
            Highscores 🕹️
          </h1>
          <hr />
          <br />

          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">CodeName</th>
                <th scope="col">correct percentage</th>
                <th scope="col">game played</th>
                <th scope="col">total score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pl, index) in result_table" :key="index">
                <td>{{ pl.name }}</td>
                <td>{{ pl.correct }}</td>
                <td>{{ pl.game_played }}</td>
                <td>{{ pl.score }}</td>
              </tr>
              <!-- tr: table row -->
            </tbody>
          </table>

          <!-- Footer -->
        </div>
      </div>
    </div>
  </v-container>
</template>

<style scoped>
.fhg-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 8rem;
  padding-right: 8rem;
  overflow: hidden;
  position: relative;
}
</style>

<script>
import axios from "axios";

export default {
  name: "HighScore",
  data() {
    return {
      msghere: "this page is for highscore",
      result_table: [],
    };
  },
  methods: {
    getResponse() {
      const path = "/backend/highscore";
      axios
        .get(path)
        .then((res) => {
          console.log(res.data);
          this.msghere = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTable() {
      const path = "/backend/highscore/table";
      axios
        .get(path)
        .then((res) => {
          this.result_table = res.data.results;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  async mounted() {
    //this.getResponse();
    this.getTable();
  },
};
</script>
